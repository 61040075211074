.request-edits {
  background-color: "#F8F8F8";
  border-radius: 24px 24px 0px 0px;
}
.request-edits .approval-header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;
  background-color: #f4f7fe;
  color: #0f63c9;
  justify-content: space-between;
  height: 140px;
  max-height: 224px;
  padding: 0 32px;
}
.request-edits .approval-header .top-container {
  display: flex;
  justify-content: flex-end;
  height: 20px;
}
.request-edits .approval-header .top-container .close-button {
  cursor: pointer;
  width: 12px;
  height: 12px;
  background: url("../../assets/icons/close-blue.svg") no-repeat center;
}
.request-edits .approval-header .header-top-wrapper {
  display: flex;
  align-items: center;
  height: 90px;
}
.request-edits .approval-header .header-top-wrapper .go-back {
  margin-right: 19px;
  cursor: pointer;
}
.request-edits .approval-header .header-top-wrapper .title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
}
.request-edits .panel {
  background-color: "#F8F8F8";
}
.request-edits .content-section {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 265px;
  width: "100%";
  background-color: "#F8F8F8";
  padding: 0 45px;
  margin-bottom: 45px;
}
.request-edits .content-section .input-description-area {
  margin-top: 48px;
}
.request-edits .content-section .input-description-area .description-title {
  font-weight: 600;
  font-size: 20px;
  color: #171720;
}
.request-edits .content-section .input-description-area .description-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #747479;
}
.request-edits .approval-footer {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: 104px;
  background-color: "#F8F8F8";
  padding: 0 45px;
}
.request-edits .approval-footer .side {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}
.request-edits .approval-footer .side.start {
  width: 33%;
  justify-content: flex-start;
}
.request-edits .approval-footer .side.end {
  width: 67%;
  justify-content: flex-end;
}

.tab-list {
  margin: 0px;
  padding: 0 32px;
}
