@import '../../../../assets/css/colors.scss';

.input-container{
    margin-top: 16px;
}

.check-container{
    flex-direction: row;
    display: flex;
    align-self: flex-start;
    padding-left: 16px;
        
    .text-coming-soon {
        color: $color-black-dark;
        font-size: 16px;
        font-weight: 400;
        line-height: 0px;
        margin-right: 8px;
    }

    .check {
        width: 16px;
        height: 16x;
    }
}