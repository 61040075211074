@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

.view-button-container{
    background-color: bisque;
}
@media (max-width: $screen-desktop-width) {
    .rounded-button {
        background-color: red;
        width: 36px !important;
        margin-right: 2px;
        height: 36px !important;
    }
}