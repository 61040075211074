@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/variables.scss';

.content-wrapper {
  @include flex-column-center;
  height: 100%;
  position: absolute;
  width: 100%;

  .login-area-container {
    @include flex-column-center;
    background-color: transparent;
    height: 100%;
    position: relative;
    width: 100%;

    .overlay {
      background-color: rgba($color: $color-dark-blue, $alpha: 0.4);
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    .logo-container {
      margin-bottom: 2.5%;
      position: relative;
      z-index: 1;
    }

    .login-area {
      @include flex-column-start-center;
      background-color: $color-white;
      border-radius: 16px;
      position: relative;
      width: 448px;
      z-index: 3;

      .form-container {
        @include flex-column-start-center;
        width: 100%;

        .title {
          color: $color-dark-blue;
          font-size: 36px;
          font-weight: 600;
          margin-top: 23px;
        }
      }
    }

    .link-button {
      height: initial;
      margin-top: 52px;
    }
  }
}

@media (max-width: $screen-midle-mobile-width) {
  .content-wrapper .login-area-container {

    .logo-container img{
      width: 7rem;
      height: 3rem;
    }


    .login-area {
      background-color: transparent;

      .form-container .title {
        color: $color-white;
      }

      .text-field .field-label {
        color: $color-white;
      }

      .wide-button {
        background-color: $color-blue-filter;

        &.disabled {
          background-color: $color-gray;
        }
      }
    }

  }
}