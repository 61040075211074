@import '../../assets/css/variables.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/screens.scss';

.indicators-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;

  .card {
    background: $color-white;
    border-radius: 8px;
    box-shadow: 0px 11px 21px rgba(57, 73, 84, 0.06);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 86px;
    margin-right: 0.8%;
    width: 214px;

    .indicator {
      display: flex;
      margin-left: 28px;
      margin-top: 14px;

      .number {
        color: $color-blue-title-content-header;
        font-family: $app-font-name;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        margin-left: 20px;
      }
    }

    .text {
      color: $color-blue-title-content-header;
      font-size: 16px;
      line-height: 24px;
      margin-left: 28px;
    }
  }


  @media (max-width: $large-desktop-screen) {
    .card {
      margin-right: 0.4%;
      width: 200px;

      .indicator {
        margin-left: 12px;
        margin-top: 8;

        .number {
          font-size: 24px;
          margin-left: 12px;
        }
      }

      .text {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 14px;
        margin-right: 4px;
      }
    }
  }

  @media (max-width: $screen-midle-mobile-width) {
    overflow: scroll;

    .card {
      height: 50px;
      margin-right: 4px;
    }
  }

  @media (max-width: $medium-screen) {
    .card {
      width: 100px;
      justify-content: center;

      .indicator {
        margin-right: 4px;
        margin-left: 0px;
        margin-top: 0px;

        .number {
          font-size: 24px;
        }
      }

      .text {
        float: right;
        width: 100px;
        opacity: 0;
        position: absolute;
        transition: ease-in-out all .4s;
      }
    }

    .card:hover {
      .text {
        opacity: 1;
      }

      .indicator {
        opacity: 0;
      }
    }
  }
}