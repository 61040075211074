@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

:root {
  --header-height: 4rem;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  justify-content: flex-start;
  margin-top: 63px;
  overflow: auto;
  .left-menu-item {
    @include flex-row-center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    height: 37px;
    cursor: pointer;
    .img-container {
      background: $color-blue-cian-light-shadow;
      border-radius: 5px;
      height: 22px;
      margin-left: 41px;
      opacity: 0.2;
      text-align: center;
      width: 22px;
    }
    .left-menu-item-text {
      color: $color-white;
      margin-left: 12px;
      white-space: nowrap;
    }
  }
  .active-item {
    border-left: 5px solid $color-blue-filter;
    .img-container {
      margin-left: 36px;
      background: $color-blue-filter;
      opacity: 1;
      img {
        filter: hue-rotate(60deg) brightness(500%);
      }
    }
  }
  .left-menu-tooltip {
    background-color: $color-blue-link;
    color: $color-white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    font-style: normal;
    z-index: 3000;
  }
}

@media (max-height: $screen-midle-desktop-height) {
  .content {
    margin-top: 8px;
  }
  .left-menu-bottom {
    bottom: 30px !important;
    .separator {
      margin: 12px 24px 8px 24px !important;
    }
    .logout {
      margin-top: 16px;
      margin-bottom: 12px;

    }
  }
}