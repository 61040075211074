@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.user-page {
    @include flex-row-start-center;
    overflow: hidden;
    overflow-x: hidden;
    background-color: $color-gray-alabaster;

    .page-content {
        @include page-content;
    }
    .table {
        margin-top: 20px;
    }

    .elipsis-icon-action-list {
        @include flex-column-center;
        cursor: default;
        margin-right: 26px;
        padding: 4px;
        width: 28px;
        height: 28px;
        border-radius: 18px;
        transform: rotate(90deg);
        &:hover {
            background: $color-gray-light;
        }
        &:active {
            background: $color-gray-light;
        }
    }
}

@media (max-width: $screen-midle-mobile-width) {
    .user-page .page-content {
        margin-top: $header-height;
    }
}