@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.events-page {
    @include flex-row-start-center;
    overflow: hidden;
    overflow-x: hidden;
    background-color: $color-gray-alabaster;
    .page-content {
        @include page-content;
        .table-fixed-head {
            margin-top: 38px;
        }
    }
}

.pagination-wraper {
    padding-right: 30px;
}

@media (max-width: $screen-midle-mobile-width) {
    .events-page {
        .page-content{
            margin-top: $header-height;
        }
    }
}