html,
body {
  margin: 0;
  padding: 0;
  font-family: "TitilliumWeb-Regular";
  height: 100%;
  font-size: 16px;
  background-color: #f8f8f8;
}

#root,
#root > div {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  white-space: pre-line;
}

@font-face {
  font-family: "TitilliumWeb-Regular";
  src: local("TitilliumWeb-Regular"), url(./assets/fonts/TitilliumWeb-Regular.ttf) format("woff");
}
textarea {
  resize: none;
}

@media (max-width: 480px) {
  #root,
  #root > div {
    width: 100%;
    height: 110vh;
  }
}
