@import '../../../../../assets/css/colors.scss';
@import '../../../../../assets/css/mixins.scss';
@import '../../../../../assets/css/variables.scss';

.options-content{
  background-color: $color-white;
  border: 1px solid #D1D1D2;
  border-radius: 12px;
  box-sizing: border-box;
  display: inline-block;
  height: 408px;
  padding: 40px 45px;
  position: absolute;
  right: 0;
  width: 437px;
  z-index: 5;

  .items-filters{
    display: flex;
    flex-direction: column;

    .text-title{
      color: $color-dark-blue;
      display: flex;
      flex: 1;
      font-family: $app-font-name;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .tags-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: $screen-midle-mobile-width) {
  .options-content{
    width: 90%;
    align-self: center;
    margin-right: 5%;
    height: unset;
  }
}