@import '../../assets/css/variables.scss';

nav {
    background-color: #394954;
    width: 95%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;

    .left-menu-logo .logo-container {
        margin-top: 0px;
    }
    .logo {
        font-size: 1.5rem;
        font-weight: bold;
        display: flex;
        margin-left: 1rem;
    }

    .mana-logo {
        display: flex;
    }

    .nav-links {
        display: flex;
        align-items: center;

        a {
            color: #ffffff;
            text-decoration: none;
            margin: 0 15px;
            font-weight: bold;
        }
    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: var(--header-height);
        margin-top: 0px;
        margin-right: 1rem;

        .active-item {
            border: none;
        }

        .left-menu-item {
            margin-left: 0px;
        }
    }

    .logout {
        margin-right: 2rem;
        margin-left: 1rem;
        display: flex;
    }


}


.drawer-background {
    background-color: rgba(0, 0, 0, 0.181);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    .drawer-content {
        width: 200px;
        height: 100%;
        background-color: #394954;
        margin-top: $header-height;
        position: absolute;
        left: 0;
    }
}


@media (min-width: $screen-midle-mobile-width) {
    nav {
        display: none;
    }

}