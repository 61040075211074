$app-font-name: 'TitilliumWeb-Regular';
$header-height: 64px;
$screen-desktop-width: 1024px;
$screen-midle-desktop-width: 980px;
$screen-midle-desktop-height: 670px;
$screen-midle-mobile-width: 480px;
$screen-midle-tablet-width: 650px;
$screen-mobile-width: 530px;
$screen-small-desktop-width: 900px;
$screen-tablet-height: 719px;
$screen-tablet-width: 784px;
